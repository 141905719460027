 .table-drag-select {
  border-collapse: separate
 } 

 .table-drag-select td.cell-enabled {
    color: rgb(255, 255, 255);
    background-color: #fff;
  }
  
  .table-drag-select td {
    border: 1px solid #D8D8D8;
    line-height: 2rem;
  }
  /* Cells that cannot be interacted with */
  .table-drag-select td.cell-disabled {
    /* Put yer css here */
  }
  
  /* Cells that have been selected */
  .table-drag-select td.cell-selected {
    background-color: #ff9c00;
  }
  
  /* Cells that are in the process of being selected */
  .table-drag-select td.cell-being-selected {
    background-color: #ff9c00;
  }
  
  /* Cells in first row */
  .table-drag-select tr:first-child>td {
    border: 0;
    position: sticky;
    top: 0;
  }
  
  /* Cells in first column */
  .table-drag-select td:first-child {
    border: 0;
    width:2rem;
  }
