.mapboxgl-popup-content {
  background-color: white;
  color: black;
}

h3 {
  font-size: 22px;
}

p {
  font-size: 14px;
}


.mapboxgl-ctrl-attrib {
  display: none;
}


.selected-layer {
  border-color: 'red';
}

.layer-buttons {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-color: 'red';
}